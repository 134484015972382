<template>
  <Layout>
    <div class="p-4">
      <b-row>
        <b-colxx xxs="12" class="">
          <b-row align-h="between" class="">
            <div class="d-flex justify-content-between">
              <h1>Mi Perfil</h1>
            </div>
          </b-row>
        </b-colxx>
        <b-colxx xxs="12" md="7" lg="5" xl="5" class="mt-3">
          <b-card class="mx-auto" no-body>
            <b-card-body>
              <div class="d-flex align-items-center justify-content-between">
                <i class="simple-icon-user" style="font-size: 60px" />
                <h4 class="mx-2 mt-2">Información Personal</h4>
                <b-button pill class="mx-1" size="sm" variant="primary" @click="toggleEdit">
                  <i class="simple-icon-pencil"></i>
                </b-button>
              </div>
              <template v-if="showMode">
                <div class="row mt-4">
                  <section class="col-md-5 col-sm-12">
                    <p class="text-muted text-small mt-3 mb-2">
                      <i class="simple-icon-speech" />
                      Nombre
                    </p>
                    <p class="mb-3">
                      {{ currentUser.first_name }} {{ currentUser.last_name }}
                    </p>
                  </section>
                  <section class="col-md-6 col-sm-12">
                    <p class="text-muted text-small mt-3 mb-2">
                      <i class="simple-icon-envelope" />
                      {{ $t('user.email') }}
                    </p>
                    <p class="mb-3">
                      {{ currentUser.email }}
                    </p>
                  </section>
                </div>
                <div class="row">
                  <section class="col-md-5 col-sm-12">
                    <p class="text-muted text-small mb-2">
                      <i class="simple-icon-phone" />
                      {{ $t('user.phone') }}
                    </p>
                    <p class="mb-3">
                      {{ currentUser.phone ? currentUser.phone : 'Sin teléfono' }}
                    </p>
                  </section>
                  <section class="col-md-6 col-sm-12">
                    <p class="text-muted text-small mb-2">
                      <i class="simple-icon-location-pin" />
                      {{ $t('user.address') }}
                    </p>

                    <p class="mb-3">
                      {{ currentUser.address ? currentUser.address : 'Sin dirección' }}
                    </p>
                  </section>
                </div>
              </template>
              <template v-else>
                <div class="text-center pt-5">
                  <b-form @submit.prevent="formSubmit" class="av-tooltip tooltip-label-bottom">
                    <input
                      ref="inputFile"
                      @change="onFilePicked"
                      type="file"
                      style="display: none"
                      accept="image/png, image/jpg, image/jpeg"
                    />
                    <b-form-group :label="$t('user.first_name')" class="has-float-label mb-4">
                      <b-form-input
                        v-model="$v.form.first_name.$model"
                        :state="!$v.form.first_name.$error"
                      />
                      <b-form-invalid-feedback v-if="!$v.form.first_name.required">
                        Debes ingresar un nombre
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group :label="$t('user.last_name')" class="has-float-label mb-4">
                      <b-form-input
                        v-model="$v.form.last_name.$model"
                        :state="!$v.form.last_name.$error"
                      />
                      <b-form-invalid-feedback v-if="!$v.form.last_name.required">
                        Debes ingresar un apellido
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group :label="$t('user.email')" class="has-float-label mb-4">
                      <b-form-input
                        type="text"
                        disabled
                        v-model="$v.form.email.$model"
                        :state="!$v.form.email.$error"
                      />
                      <b-form-invalid-feedback v-if="!$v.form.email.required">
                        Debes ingresar un correo electrónico
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else-if="!$v.form.email.email">
                        Debes ingresar un correo electrónico válido
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else-if="!$v.form.email.minLength">
                        Debes ingresar un correo electrónico con mínimo 4 caracteres
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group :label="$t('user.phone')" class="has-float-label mb-4">
                      <b-form-input
                        type="text"
                        v-model="$v.form.phone.$model"
                        :state="!$v.form.phone.$error"
                      />
                      <b-form-invalid-feedback v-if="!$v.form.phone.required">
                        Debes ingresar un teléfono
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group :label="$t('user.address')" class="has-float-label mb-4">
                      <b-form-textarea
                        v-model="form.address"
                        rows="3"
                        no-resize
                      ></b-form-textarea>
                    </b-form-group>
                    <div class="d-flex justify-content-end align-items-center">
                      <b-button
                        variant="outline-danger"
                        @click="cancel"
                        size="md"
                        class="mx-2"
                        :disabled="processing"
                      >
                        <span class="label">Cancelar</span>
                      </b-button>
                      <b-button type="submit" :disabled="processing" variant="outline-dark">
                        <b-spinner v-if="processing" class="mx-2" small></b-spinner>
                        Actualizar
                      </b-button>
                    </div>
                  </b-form>
                </div>
              </template>
            </b-card-body>
          </b-card>
        </b-colxx>
      </b-row>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main'
import appConfig from '@/app.config'
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
const { required, minLength, email } = require('vuelidate/lib/validators')

export default {
  name: 'Profile',
  page: {
    title: `Perfil`,
    meta: [{ name: 'description', content: appConfig.description }]
  },
  components: {
    Layout
  },
  watch: {
    currentUser: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setLocalData(newVal)
        }
      }
    }
  },
  data() {
    return {
      processing: false,
      updateProfileError: null,
      showMode: true,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        image: null
      },
      selectedImg: null
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      email: {
        required,
        email,
        minLength: minLength(4)
      },
      phone: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({ currentUser: 'user/currentUser' })
  },
  methods: {
    ...mapActions({ updateProfile: 'user/updateProfile' }),
    setLocalData(currentUser) {
      const { email, first_name, last_name, phone, address } = currentUser
      this.form = {
        first_name: first_name,
        last_name: last_name,
        email,
        phone,
        address
      }
    },
    toggleEdit() {
      this.showMode = !this.showMode
    },
    removeImgSelected() {
      this.form.image = null
      this.selectedImg = null
    },
    cancel() {
      this.removeImgSelected()
      this.toggleEdit()
    },
    pickFile() {
      this.$refs.inputFile.click()
    },
    onFilePicked(e) {
      const { files } = e.target
      const fr = new FileReader()
      if (files[0] !== undefined) {
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.form.image = files[0]
          this.selectedImg = fr.result
        })
      }
    },
    async formSubmit() {
      this.processing = true
      const { form } = this
      const success = await this.updateProfile(form)
      console.log(success)
      if (success) {
        this.cancel()
      }
      this.processing = false
    }
  }
}
</script>

<style>
.img-actions {
  height: 80px;
  width: 80px;
}

#avatar-profile > span.b-avatar-badge.badge-light {
  padding: 0 0 0.2rem 0 !important;
  background-color: transparent;
  min-height: auto;
  min-width: auto;
}
</style>
