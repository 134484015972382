<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden px-2">
          <div class="bg-soft p-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-7">
                <div class="text-white">
                  <h2 class="text-white">{{ nameApp }}</h2>
                  <h6 class="text-white">Iniciar Sesión con Google</h6>
                </div>
              </div>
              <div class="col-5 align-self-end mb-3">
                <img :src="logoApp" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <h2 class="mb-4 text-center mt-5">Iniciando Sesión Espere..</h2>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <Footer></Footer>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/auth'
import appConfig from '@/app.config'
import { mapGetters, mapActions } from 'vuex'
import { monitoringUrl, nameApp, logoApp } from '@/constants/config'
import Footer from './components/footer.vue'
export default {
  page: {
    title: 'Verificar inicio de sesión con Google',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    Footer
  },
  data() {
    return {
      nameApp,
      logoApp
    }
  },
  computed: {
    ...mapGetters({ currentUser: 'user/currentUser' })
  },
  methods: {
    ...mapActions({ loginGoogle: 'user/loginGoogle' }),
    async loginWithGoogle() {
      try {
        const paramsLogin = this.$route.query
        const resp = await this.loginGoogle(paramsLogin)
        if (resp) {
          setTimeout(() => {
            this.$router.push(monitoringUrl)
          }, 1000)
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  created() {
    this.loginWithGoogle()
  }
}
</script>

<style></style>
